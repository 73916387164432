import "./App.css";

const Gallery = () => {
    return (
        <div id="gallery" className="pt-10">
            <section class="w-full mb-40 relative">
                <div class="max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 mt-32 grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div class="col-span-1 md:col-span-2 mb-16">
                        <h1 class="text-5xl md:text-7xl font-bold font-display mb-2">Gallery</h1>
                        {/* <h2 class="text-3xl font-display opacity-70">Aftermath NFTs</h2> */}
                    </div>
                </div>
                <div class="max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 grid grid-cols-6 gap-8">
                    <div class="col-span-5 md:col-span-4">
                        <div class="col-span-5 md:col-span-4 grid grid-cols-1 md:grid-cols-6 gap-4">
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-0.jpeg" alt="Aftermath NFT" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-1.jpeg" alt="Aftermath NFT" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-2.jpeg" alt="Aftermath NFT" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-3.jpeg" alt="Aftermath NFT" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-4.jpeg" alt="Aftermath NFT" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-5.jpeg" alt="Aftermath NFT" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-6.jpeg" alt="Aftermath NFT" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-7.jpeg" alt="Aftermath NFT" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-8.jpeg" alt="Aftermath NFT" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-9.jpeg" alt="Aftermath NFT0" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-10.jpeg" alt="Aftermath NFT1" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-11.jpeg" alt="Aftermath NFT2" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-12.jpeg" alt="Aftermath NFT3" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-13.jpeg" alt="Aftermath NFT4" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-14.jpeg" alt="Aftermath NFT5" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-15.jpeg" alt="Aftermath NFT6" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-16.jpeg" alt="Aftermath NFT7" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-23.jpeg" alt="Aftermath NFT8" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-32.jpeg" alt="Aftermath NFT9" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-42.jpeg" alt="Aftermath NFT0" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-52.jpeg" alt="Aftermath NFT1" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-62.jpeg" alt="Aftermath NFT2" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-72.jpeg" alt="Aftermath NFT3" class="rounded-md z-0" />
                            </div>
                            <div class="text-center overflow-hidden relative flex flex-col items-center justify-center">
                                <img src="/gallery/aftermath-82.jpeg" alt="Aftermath NFT4" class="rounded-md z-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Gallery;
