import "./App.css";
import { useMemo } from "react";

import Minter from "./Minter";
import Gallery from "./Gallery";
import Vision from "./Vision";
import Home from "./Home";

import styled from "styled-components";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
  getMathWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { ThemeProvider, createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
  ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
  : undefined;

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);
const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const H1 = styled.h1`
  text-align: center;
  margin-bottom: 10rem;
`;

const StageOneListContainer = styled.ul`
  list-style: none;
  width: 50%;
  margin-left: 50%;
  position: relative;
  padding-left: 1rem;
  // padding-top: 1px;
  // padding-bottom: 1px;
  &::before {
    content: "";
    display: block;
    height: 100%;
    width: 1px;
    background-color: white;
    position: absolute;
    left: 0;
  }
  &::after {
    // content: "01";
    // display: block;
    // border: 2px solid white;
    // border-radius: 50%;
    // position: absolute;
    // top: -56px;
    // left: -29px;
    // padding: 10px 18px;
  }
  @media (max-width: 1023px) {
    padding-left: 0;
    width: 100%;
    margin-left: initial;
    &::before {
      display: none;
    }
  }
`;

const StageTwoListContainer = styled.ul`
  width: 50%;
  margin-left: 50%;
  position: relative;
  padding-left: 6rem;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-top: 57px;
  margin-bottom: 5rem;
  &::before {
    content: "";
    display: block;
    height: 45px;
    width: 1px;
    background-color: white;
    position: absolute;
    left: 0;
  }
  &::after {
    content: "02";
    display: block;
    border: 2px solid white;
    border-radius: 50%;
    position: absolute;
    top: -56px;
    left: -29.9px;
    padding: 10px 18px;
  }
  @media (max-width: 1023px) {
    padding-left: 4rem;
    width: 100%;
    margin-left: initial;
  }
`;

const ListItem = styled.li`
  margin: 3rem 0;
  position: relative;
  &:first-of-type {
    margin-top: 4rem;
  }
  &:last-of-type {
    margin-bottom: 4rem;
  }
  &:before {
    content: "";
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    border: 2px solid white;
    background-color: black;
    position: absolute;
    left: -22.1px;
    top: 10px;
  }
  &:nth-of-type(2n) {
    position: relative;
    text-align: right;
    left: -85%;
    padding-right: 6rem;
    &:before {
      right: 4.76rem;
      left: initial;
    }
  }
  @media (max-width: 1023px) {
    &:before {
      content: "";
      width: 10px;
      height: 10px;
      display: none;
      border-radius: 50%;
      border: 2px solid white;
      background-color: black;
      position: absolute;
      left: -70px;
    }
    &:nth-of-type(2n) {
      position: initial;
      text-align: initial;
      left: initial;
      padding-right: initial;
      &:before {
        right: initial;
        left: -19px;
      }
    }
  }
`;

const Chapter = styled.h5`
  font-size: 1.6rem;
  margin: 0 0 1rem;
  text-transform: uppercase;
`;

const SubListContainer = styled.ul`
  margin-top: 1rem;
`;
const SubListItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  div:first-of-type {
    margin-right: 1.5rem;
  }
  div:nth-of-type(2) {
    flex: 2;
  }
`;

const Icon = styled.img`
  height: 20px;
  width: 20px;
  margin-top: 10px;
`;

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSolletWallet(),
      getMathWallet(),
    ],
    []
  );

  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer")!;
    menu.classList.toggle("open-menu");
    // console.log("pressed");
  }

  return (
    <div>
      {/* {console.log(connection, "connection connection")} */}
      <div id="mobileNavContainer" className="mobile-nav">
        <div className="mobile-nav-close-button">
          <img src="/icons/close.svg" alt="" onClick={toggleMenu} />
        </div>
        <ul>
          <li>
            <a href="/">Aftermath</a>
          </li>
          <li>
            <a href="/#vision" onClick={toggleMenu}>
              Vision
            </a>
          </li>
          <li>
            <a href="/#gallery" onClick={toggleMenu}>
              Gallery
            </a>
          </li>
          <li>
            <div className="social-icons">
              <a
                href="https://twitter.com/aftermathnft"
                target="_blank"
                rel="noreferrer"
              >
                <img className="nav-social" src="/icons/twitter.svg" alt="" />
              </a>
              <a
                href="https://discord.gg/tQGK82WYYF"
                target="_blank"
                rel="noreferrer"
              >
                <img className="nav-social" src="/icons/discord.svg" alt="" />
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div className="mobile-menu-button" onClick={toggleMenu}>
        <img src="/icons/menu.svg" alt="" />
      </div>
      <nav>
        <div className="nav-container">
          <a href="/">Aftermath</a>
          <a className="" href="/#vision">
            Vision
          </a>
          <a className="" href="/#gallery">
            Gallery
          </a>
          <div className="social-icons">
            <a
              href="https://twitter.com/aftermathnft"
              target="_blank"
              rel="noreferrer"
            >
              <img className="nav-social" src="/icons/twitter.svg" alt="" />
            </a>
            <a
              href="https://discord.gg/tQGK82WYYF"
              target="_blank"
              rel="noreferrer"
            >
              <img className="nav-social" src="/icons/discord.svg" alt="" />
            </a>
          </div>
        </div>
      </nav>
      <div className="">
        {/* <h1 className="text-center pb-3">Join the DefiDucks!</h1> */}
        {/* <header className="" id="link1">
          <div style={{ padding: "0 24px 0 24px 0" }}>
            <img className="gif-logo" src="/img/gif.gif" alt="" />
          </div>
          <div>
            <ThemeProvider theme={theme}>
              <ConnectionProvider endpoint={endpoint}>
                <WalletProvider wallets={wallets} autoConnect>
                  <WalletDialogProvider>
                    <Minter
                      candyMachineId={candyMachineId}
                      connection={connection}
                      startDate={startDateSeed}
                      txTimeout={txTimeout}
                      rpcHost={rpcHost}
                    />
                  </WalletDialogProvider>
                </WalletProvider>
              </ConnectionProvider>
            </ThemeProvider>
          </div>
        </header> */}

        <Home />
        <Vision />
        <Gallery />
      </div>
    </div>
  );
};

export default App;
