import "./App.css";

const Vision = () => {
    return (
        <div id="vision" className="pt-10">
            <section class="w-full min-h-screen md:mb-40">
                <div class="max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 mt-32 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="col-span-1 md:col-span-2 mb-16">
                        <h1 class="text-5xl md:text-7xl font-bold font-display">Vision</h1>
                    </div>

                    <div class="md:pr-16">
                        <h2 class="text-4xl font-display opacity-70 mb-8">The Norm</h2>
                        <p class="text-xl mb-8 leading-relaxed">The current norm for projects is to establish a
                            roadmap that expands a year in advance of project launch. We believe this to be a limiting factor to a lot
                            of projects. Promising something well in advance that may or may not end up coming to fruition can cause
                            distrust within a community. Which could eventually lead to its downfall.
                        </p>
                        <p class="text-xl leading-relaxed mb-8">Not to mention, some of the greatest projects have
                            climbed the charts by incorporating items that never were part of their roadmap. Most of the time being
                            the most requested item by the community itself.</p>
                        <p class="text-xl leading-relaxed mb-8">Community is everything.</p>
                        <p class="text-xl leading-relaxed mb-8">To combat current norms we will not be having a stage
                            based roadmap. Instead we will provide the community with our goals and aspirations. Alongside that we
                            will incorporate most requested features that the community desires to reach a bright future together.</p>
                    </div>
                    <div>
                        <img src="/gallery/aftermath-1.jpeg"
                            alt="Aftermath NFT."
                            class="rounded-xl" />
                    </div>
                </div>

                <div class="max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 mt-32 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="md:pr-16">
                        <img src="/gallery/aftermath-3.jpeg"
                            alt="Aftermath NFT."
                            class="rounded-xl" />
                    </div>
                    <div>
                        <h2 class="text-4xl font-display opacity-70 mb-8">Building the Brand</h2>
                        <p class="text-xl mb-8 leading-relaxed">At the forefront of our goals is brand awareness. This
                            starts with attention to detail with every post we make, as well as the quality of our work.</p>
                        <p class="text-xl leading-relaxed mb-8">As we all know, the NFT market is vast. Standing out is
                            key. We believe we will stand out to other projects by the way we (the team) and you (the community)
                            conducts ourselves. Our goal is to build a community consisting of members who want to do better, and be
                            better to others and themselves.</p>
                        <p class="text-xl leading-relaxed mb-8">As corny as it sounds, teamwork makes the dreamwork. We
                            fully believe that projects become successful due to their communities. Community sentiment starts with the
                            team. We plan on treating every member with the upmost respect, and informed of future developments.</p>
                        <p class="text-xl leading-relaxed mb-8">With a community of likemindedness, we believe our
                            global outreach efforts will be successful. Whether that be with other NFT communities, or global brands. We
                            intend to give every collaboration our attention. In turn reaching audiences that may have never been tapped
                            before.</p>
                    </div>
                </div>

                <div class="max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 mt-32 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="md:pr-16">
                        <h2 class="text-4xl font-display opacity-70 mb-8">Community Incentives</h2>
                        <p class="text-xl leading-relaxed mb-8">To start, every holder will be granted access into our
                            discord community. This community will be exclusive to holders, and consist of several resources that will
                            help members expand their social reach and their access to new project drops. </p>
                        <p class="text-xl leading-relaxed mb-8">In addition, we plan on implementing holder based
                            incentives. As well as collaborative based incentives, rewarding members for their efforts in spreading the
                            word of Hello Pantha. We believe that this gives more opportunity to holders that aren't able to purchase
                            large quantities of our project. While we don't have every detail for how this system will work, we plan on
                            implementing it.
                        </p>
                        <p class="text-xl leading-relaxed mb-8">We plan on creating an interactive dashboard with all
                            information and data about the entire collection as a whole, as well as your own data. To expand upon this,
                            we want to implement leaderboard based data to include several different categories that update in real
                            time. Giving users trying to grow a social following the stage to do so.
                        </p>
                    </div>
                    <div>
                        <img src="/gallery/aftermath-2.jpeg"
                            alt="Aftermath NFT."
                            class="rounded-xl" />
                    </div>
                </div>

                <div class="max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 mt-8 grid grid-cols-1 gap-4">
                    <p class="text-xl leading-relaxed mb-8">We are not entirely opposed to a token based system to
                        reward holders as well. As we begin building our communities foundation, we will collaborate with you to
                        come to terms on the best system that treats the small fish & big fish fairly.
                    </p>
                    <p class="text-xl leading-relaxed mb-8">A welcome gift box is a very cool concept we have been
                        tossing around. Such as a framed picture of your NFT shipped to your door, or a clothing item. While this
                        has been tried before, and sometimes has been the only utility for some projects to advertise - we believe
                        it to be a very useful tool that aligns with our goal to build a global brand.
                    </p>
                    <p class="text-xl leading-relaxed mb-8">The most ultimate community incentive can be seen if we
                        do everything correctly. As we expand our outreach, the simple laws of supply & demand will prove to reward
                        holders the most. With a team of over 10 members, we plan to do whatever it takes to reward our most
                        valuable asset.
                    </p>
                </div>
            </section>
        </div>
    );
};

export default Vision;
