import "./App.css";

const Home = () => {

    return (
        <div id="home">
            <section class="relative w-screen h-screen max-h-screen overflow-hidden">
                <div class="w-full h-full flex flex-col items-center justify-center md-justify-between">
                    <div></div>
                    <h1 class="text-5xl md:text-9xl font-display font-bold transform scale-150 -mt-36 lg:mt-0">Aftermath
                    </h1>
                    <h2 class="text-opacity-0 font-bold font-display my-4 md:text-opacity-70">Minting soon on Solana</h2>
                </div>
                <img src="/gallery/aftermath.png"
                    alt="Aftermath NFT."
                    class="absolute bottom-0 left-90 w-auto md:h-1/2" />
                <img src="/gallery/imageflipped2.png"
                    alt="Aftermath NFT."
                    class="absolute bottom-0 right-90 w-auto md:h-1/2" />
                {/* <div class="p-4 absolute right-0 bottom-0 w-full md:w-auto">
                    <div
                        class="flex bg-orange-50 shadow-sm md:gap-x-8 hover:shadow-md transition-shadow justify-between p-4 rounded-md items-center w-full">
                        <div class="flex items-center gap-x-4"><img src="/gallery/emoji_see-no-evil@3x.png"
                            alt="An emoji of an Ape, with his hands covering his eyes." class="w-8" />
                            <div>
                                <p class="font-display text-sm font-bold text-stone-700">Ape in.</p>
                                <p class="font- text-xs font-medium text-stone-600 text-opacity-">You&#x27;re still early.</p>
                            </div>
                        </div><a href="https://magiceden.io/marketplace/justape" target="_blank" rel="noreferrer"
                            class="font-display text-xs uppercase tracking-wider font-bold px-3 bg-red-800 rounded py-2 text-red-50 transition-all hover:bg-red-900 hover:ring-4 ring-red-500 ring-opacity-30">Buy
                            →</a>
                    </div>
                </div> */}
            </section>
        </div>
    );
};

export default Home;
